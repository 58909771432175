.input-no-outline {
  border: 0;
  border-bottom: 1px solid grey;
  outline: none;
}

.input-no-outline:focus {
  outline: none;
}
textarea {
  resize: none;
}
.custom-box-shadow {
  box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.05); /* Offset X and Y are 0, blur is 35px, spread is 5px, and opacity is 10% */
}